import request from '@/utils/request'

export function getNewsList(params) {
    return request({
        url: '/notice/page',
        method: 'GET',
        params,
    })
}

export function createNews (data) {
    return request({
        url: data.id ? `/notice/update/${data.id}` : `/notice/create`,
        method: 'POST',
        data
    })
}

export function deleteNews(data) {
    return request({
        url: '/notice/delete',
        method: 'POST',
        data,
    })
}








