<template>
  <div class="full-card has-table">
    <div class="handle-area">
      <a-button type="primary" icon="plus" @click="openCreate('create')" v-has-permission="['addNotice']">新增公告新闻</a-button>
    </div>
    <a-card>
      <a-table
          :columns="columns"
          :data-source="list"
          :loading="tableLoading"
          :pagination="pagination"
          rowKey="id"
          bordered
          @change="pageChange"
          :scroll="{ y: 'calc(100% - 55px)'}"
      >
        <template slot="noticeType" slot-scope="text">
          <span v-if="text === 1">重要通知</span>
          <span v-if="text === 2">通知</span>
          <span v-if="text === 3">模板消息</span>
        </template>fo
        <template slot="noticeContent" slot-scope="text">
          <span>{{ text | texts }}</span>
        </template>
        <template slot="id" slot-scope="text, record">
          <img src="@/assets/image/common/edit.png" alt="" @click="openCreate('edit', record)" v-has-permission="['editNotice']">
          <img src="@/assets/image/common/delete.png" alt="" class="margin-l20" @click="delItem(record)" v-has-permission="['deleteNotice']">
        </template>
      </a-table>
    </a-card>
    <a-drawer
        :title="`${ form.id ? '编辑' : '新增'}公告新闻` "
        placement="right"
        :visible="createShow"
        @close="closeCreate"
        width="900px"
        class="drawer"
    >
      <div class="drawer-cot">
        <a-form-model
            ref="form"
            labelAlign="right"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 20 }"
            :model="form"
            :rules="rules"
            class="drawer-form"
        >
          <a-row>
            <a-form-model-item label="消息标题" prop="noticeTitle">
              <a-input placeholder="请输入消息标题" v-model="form.noticeTitle"/>
            </a-form-model-item>
            <a-form-model-item label="消息类型" prop="noticeType">
              <a-select v-model="form.noticeType" placeholder="请选择消息类型">
                <a-select-option :value="1">重要通知</a-select-option>
                <a-select-option :value="2">通知</a-select-option>
                <a-select-option :value="3">模板消息</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="备注" prop="noticeContent">
              <QuillEditor v-model="form.noticeContent"></QuillEditor>
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="check" @click="onSubmit">确定</a-button>
          <a-button icon="close" class="margin-l20" @click="closeCreate">取消</a-button>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { getNewsList, createNews, deleteNews } from '../../api/system/news'
import { mapState } from 'vuex'
import QuillEditor from '@/components/Editor/QuillEditor'

export default {
  name: 'message',
  components: {
    QuillEditor
  },
  data () {
    return {
      columns: [
        {
          title: '消息标题',
          dataIndex: 'noticeTitle'
        },
        {
          title: '消息类型',
          dataIndex: 'noticeType',
          scopedSlots: { customRender: 'noticeType' }
        },
        {
          title: '正文',
          dataIndex: 'noticeContent',
          scopedSlots: { customRender: 'noticeContent' }
        },
        {
          title: '最近修改时间',
          dataIndex: 'updateTime'
        },
        {
          title: '修改人',
          dataIndex: 'modifier'
        },
        {
          title: '操作',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' }
        }
      ],
      list: [],
      total: 0,
      createShow: false,
      form: {},
      page: 1,
      roleList: [],
      tableLoading: true,
      pagination: {
        total: 0,
        pageSize: 10
      },
      rules: {
        noticeTitle: [
          { required: true, message: '请输入消息标题', trigger: 'blur' }
        ],
        noticeType: [
          { required: true, message: '请选择消息类型', trigger: 'change' }
        ]
      },
      roleTree: [],
      visible: false,
      replaceFields: {
        title: 'deptName',
        key: 'id',
        value: 'id'
      },
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      return new Promise( (resolve) => {
        this.tableLoading = true
        getNewsList(this.filterForm).then(res => {
          this.list = res.data.list
          this.pagination.total = res.data.total
          this.tableLoading = false
          resolve(res.data)
        })
      })
    },
    pageChange (pages) {
      this.filterForm.pageNum = pages.current
      this.getList()
    },
    openCreate (type, item) {
      if (item) this.form = JSON.parse(JSON.stringify(item))
      this.createShow = true
    },
    closeCreate () {
      this.form = {}
      this.createShow = false
    },
    delItem (item) {
      this.$confirm({
        title: '提示',
        content: '确认删除这条数据？',
        centered: true,
        onOk: () => {
          deleteNews({
            ids: [item.id]
          }).then(() => {
            this.$message.success('删除成功！')
            this.getList()
          })
        }
      })
    },
    onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          createNews(this.form).then(() => {
            this.$message.success('操作成功！')
            this.getList()
            this.createShow = false
          })
        }
      })
    },
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped>

</style>
